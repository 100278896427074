import React, {useEffect} from 'react';
import Layout from '../components/Layout';
import SimpleHeaderModule from '../components/SimpleHeaderModule';

export default () => {
  useEffect(() => {
    window.location.replace('https://calendar.google.com');
  }, []);

  return (
    <Layout pageTitle="Thanks!" description="Thanks for installing Miter for Chrome.">
      <SimpleHeaderModule title="Thanks for Installing!">Redirecting...</SimpleHeaderModule>
    </Layout>
  );
};
